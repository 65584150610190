<template>
  <div class="home">
<Navigation/>
    <div class="section bg-primary-3">
    <div class="container text-center">
      <h1>Myth Or Fact</h1>
    </div>
  </div>
<Posts Category="myth-or-fact" />


  </div>

</template>

<script>
import Navigation from "@/components/Navigation.vue"
import Posts from "@/components/PostCollection.vue"

export default {
  name: "home",
  components: {
    Navigation,
    Posts
  }
};
</script>
